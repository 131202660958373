<template>
  <div class="main-content">
    <div class="layout-px-spacing mb-5">
      <div class="row layout-top-spacing">
        <div
          class="col-12 col-md-12 col-lg-8 offset-lg-2 layout-spacing bg-white p-5 shadow"
        >
          <form>
            <div class="row mb-4">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="name">Sponsor Name</label>
                  <input
                    type="text"
                    id="name"
                    v-model="data.user.name"
                    readonly
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="email">Sponsor Email</label>
                  <input
                    type="text"
                    id="email"
                    v-model="data.user.email"
                    readonly
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="phone">Sponsor Phone</label>
                  <input
                    type="text"
                    id="phone"
                    v-model="data.user.phone"
                    readonly
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="location">Address</label>
                  <input
                    type="text"
                    id="location"
                    v-model="data.user.address"
                    readonly
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-12 mt-3">
                <div class="text-center text-success">Patients</div>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Elderly Name</th>
                      <th>Elderly Phone</th>
                      <th>Elderly Address</th>
                      <th>Visits Left</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="patient in data.user.patients" :key="patient.id">
                      <td>{{ patient.firstname }} {{ patient.lastname }}</td>
                      <td>{{ patient.email }}</td>
                      <td>{{ patient.address }}</td>
                      <td>{{ patient.visit_count }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="col-12 mt-3">
                <div class="text-center text-success">Transaction History</div>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Elderly Name</th>
                      <th>Service</th>
                      <th>Amount Paid</th>
                      <!-- <th>Duration</th> -->
                      <th>Pay Mode</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="transaction in data.user.transactions"
                      :key="transaction.id"
                    >
                      <td>
                        {{ transaction.patient.firstname }}
                        {{ transaction.patient.lastname }}
                      </td>
                      <td>{{ transaction.service.service_name }}</td>
                      <td>{{ formatMoney(transaction.pay_amount) }}</td>
                      <td>
                        {{ transaction.pay_mode == 1 ? 'Card' : 'Bank Transfer' }}
                        <!-- <span v-if="transaction.pay_mode == 1">Card</span>
                        <span v-if="transaction.pay_mode == 2"
                          >Bank Transfer</span
                        > -->
                      </td>
                      <td>
                        {{ formatFancyDate(transaction.created_at) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="col-12 mt-5">
                <hr />

                <div class="row" id="actionBox">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="">New Call Record</label>
                      <textarea
                        class="form-control"
                        v-model="update"
                        name=""
                        id=""
                        rows="3"
                      ></textarea>
                    </div>
                  </div>

                  <div class="col-12 text-center mt-3">
                    <button
                      type="button"
                      @click.prevent="saveUpdate()"
                      class="btn btn-primary"
                      :disabled="loadingNewUpdate"
                    >
                      <b-spinner small v-if="loadingNewUpdate"></b-spinner>
                      <span v-else>
                        Save <i class="fa fa-paper-plane"></i
                      ></span>
                    </button><button
                      class="btn btn-primary ml-2"
                      type="button"
                      data-toggle="modal"
                      data-target="#reminder"
                      title="Set reminder"
                    >
                      <b-spinner
                        small
                        v-if="loading"
                      ></b-spinner>
                      <span v-else>
                        <span><i class="fa fa-bell"></i></span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-3">
                <div class="timeline-simple">
                  <div class="card mb-4" v-if="reminder">
                  <div class="card-body bg-success">
                    <h5 class="text-white border-bottom">
                      <i class="fa fa-bell"></i> Reminder
                    </h5>
                    <div class="bg-success font-weight-bold">
                      {{ reminder.reminder_note }}
                    </div>
                    <div class="font-weight-bold">
                      <i class="fa fa-clock"></i>
                      {{
                        formatFancyDate(
                         reminder.reminder_date 
                        )
                      }}
                    </div>
                  </div>
                </div>
                  <p class="timeline-title">Call Logs</p>

                  <div class="mt-3 text-center" v-if="loadingFetchAction">
                    <b-card class="">
                      <b-skeleton animation="wave" width="85%"></b-skeleton>
                      <b-skeleton animation="wave" width="67%"></b-skeleton>
                      <b-skeleton animation="wave" width="70%"></b-skeleton>
                      <b-skeleton animation="wave" width="82%"></b-skeleton>
                      <b-skeleton animation="wave" width="65%"></b-skeleton>
                      <b-skeleton animation="wave" width="90%"></b-skeleton>
                      <b-skeleton animation="wave" width="95%"></b-skeleton>
                    </b-card>
                  </div>

                  <div
                    class="timeline-list my-4"
                    v-for="(remark, index) in data.remarks"
                    :key="index"
                  >
                    <div class="timeline-post-content">
                      <div class="user-profile">
                        <img
                          :src="`${url}/uploads/images/${remark.user.image}`"
                          class=""
                          alt="user photo"
                        />
                      </div>
                      <div class="">
                        <h4>
                          {{ remark.user.firstname }} {{ remark.user.lastname }}
                        </h4>
                        <p class="meta-time-date">
                          {{ formatFancyDate(remark.created_at) }}
                        </p>
                        <div class="">
                          <h6 class="">Update</h6>
                          <p class="post-text">{{ remark.remark }}</p>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="reminder"
      role="dialog"
      aria-labelledby="reminder"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="reminder">Set Reminder</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <form method="post" @submit.prevent="saveReminder()">
                  <div class="form-group">
                    <label for="dateSearch">Reminder Note</label>
                    <input
                      class="form-control"
                      v-model="note"
                      id="note"
                      type="text"
                      placeholder="What do you want to be reminded of?"
                    />
                  </div>
                  <div class="form-group">
                    <label for="date">Date of Reminder</label>
                    <input
                      type="date"
                      name="date"
                      id="date"
                      class="form-control"
                      v-model="reminder_date"
                    />
                  </div>
                  <button class="btn btn-default m-2" data-dismiss="modal">
                    <i class="fa fa-cancel"></i> Cancel
                  </button>
                  <button type="submit" class="btn btn-primary m-2">
                    <b-spinner small v-if="loading"></b-spinner>
                      <span v-else>
                        Save <i class="fa fa-paper-plane"></i
                      ></span>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { endpoints } from "@/utils/endpoints";
import { http } from "@/utils/http";

import { mapGetters } from "vuex";

var moment = require("moment");

export default {
  name: "ExpiredSubCallLogs",

  data() {
    return {
      loading: false,
      data: [],

      update: "",
      reminder: [],

      loadingNewUpdate: false,
      loadingFetchAction: false,
      note: "",
      reminder_date: "",
    };
  },

  computed: {
    ...mapGetters(["url", "user"]),
  },

  mounted() {
    if (
      typeof this.$route.params.data == "undefined" ||
      this.$route.params.data.length == 0
    ) {
      this.$router.push("/calls/expiredsub");
    }
    this.data = this.$route.params.data;
    this.reminder = this.$route.params.data.reminder[0]
  },

  methods: {
    saveUpdate() {
      if (this.update == "") {
        this.$toast.error("Call update field is required. Please try again.");
      } else {
        this.loadingNewUpdate = true;
        this.loadingFetchAction = true;
        http
          .put(endpoints.NEW_EXPIRED_SUB_REMARK, {
            sponsor_id: this.data.user_id,
            remark: this.update,
          })
          .then((response) => {
            this.loadingNewUpdate = false;
            this.loadingFetchAction = false;

            this.$toast.success(response);
            var newremark = {
              user: this.user,
              remark: this.update,
              created_at: moment().format(),
            };
            this.data.remarks.unshift(newremark);
            this.update = "";
          })
          .catch((error) => {
            this.loadingNewUpdate = false;
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          });
      }
    },
    saveReminder() {
      if (this.note.length == 0 || this.reminder_date.length == 0) {
        this.$toast.error("The Note and Date field must be provided");
        return;
      }

      this.loading = true;
      this.reminder = ''

      http
        .post(
          endpoints.STORE_EXPIRED_SUB_REMINDER,
          {
            reminder: this.note,
            reminder_date: this.reminder_date,
            expired_subscriber_id: this.$route.params.data.id,
            sponsor_id: this.$route.params.data.user_id,
            sponsor_name: this.$route.params.data.user.name
          }
        )
        .then((response) => {
          $("#reminder").modal("hide");

          this.$toast.success(response.message);
          this.reminder = response.data
          
          this.loading = false
        })
        .catch((error) => {
          this.loading = false

          this.$toast.error("An error occured, please try again later");
          this.$toast.error(
              error.response?.data?.message || error.response?.message
            );

        })
    },

    roundUp(x) {
      return x.toPrecision(2);
    },
  },
};
</script>

<style
  scoped
  src="@/assets/css/components/timeline/custom-timeline.css"
></style>
<style scoped>
.new-control {
  font-weight: 400;
  font-size: 15px;
}
.tableInput {
  border: none !important;
  font-size: 14px;
}
</style>
